import { useEffect, useState } from 'react';
import { useResolveQueryValue } from '~/libs/useResolveQueryValue';
import { FlowType, isFlowType } from '~/types/flowType';

type State =
  | { loaded: false }
  | {
      loaded: true;
      flowType: { valid: false } | { valid: true; value: 'self' | 'support' | 'others' };
      salesAgencyCode: { value: string | null };
      salesOutsourceCode: { value: string | null };
    };

// NOTE: LPまわりの指標に依存しているので以下の仕様を確認してください
// https://taimee.slack.com/archives/C036E9X2A91/p1678679793155639
const queryConverter = (query: string | undefined): FlowType | null => {
  if (query === undefined) return 'others';
  if (isFlowType(query)) return query;
  return 'others';
};

export const useSelfQueryValue = (): State => {
  const [state, setState] = useState<State>({ loaded: false });

  const flowType = useResolveQueryValue('flow_type', queryConverter);
  const salesAgencyCode = useResolveQueryValue('sales_agency_code', (query) => query);
  const salesOutsourceCode = useResolveQueryValue('sales_outsource_code', (query) => query);

  useEffect(() => {
    if (flowType.loaded && salesAgencyCode.loaded && salesOutsourceCode.loaded)
      setState({
        loaded: true,
        flowType: flowType.valid ? { valid: true, value: flowType.value } : { valid: false },
        salesAgencyCode: salesAgencyCode.valid
          ? // sales_agency_codeのキー自体が指定されていない時はnullとして扱う
            { value: salesAgencyCode.value === undefined ? null : salesAgencyCode.value }
          : { value: null },
        salesOutsourceCode: salesOutsourceCode.valid
          ? // salesOutsourceCodeのキー自体が指定されていない時はnullとして扱う
            {
              value: salesOutsourceCode.value === undefined ? null : salesOutsourceCode.value,
            }
          : { value: null },
      });
  }, [flowType, salesAgencyCode, salesOutsourceCode]);

  return state;
};
